.long {
  white-space: nowrap;
  width: auto;
}

.shifted {
  padding-left: 25px;
}

.actionButton {
  font-size: 12px;
  color: #007CB0;
  background-color: transparent;
}

.actionButton:focus {
  background-color: #D6F0FA;
}

.check * {
  cursor: pointer;
}

.form{
  height: 150px;
  width: 148px; 
}