.button {
  text-align: center;
  min-width: 86px;
  text-transform: uppercase;
}

.button:disabled {
  background-color: #d0d0ce !important;
  opacity: 1 !important;
  border-color: #d0d0ce !important;
}

.button.btnSm {
  font-size: 0.8125rem;
}

.button.btnOutlinePrimary:hover {
  color: #007CB0;
  background-color: #ebf8fd;
}

.button.btnOutlinePrimary:focus {
  color: #007CB0;
  background-color: #d6f0fa;
}

.button.btnOutlineSecondary {
  border: 1px solid #007CB0;
  color: #007CB0;
}

.button.btnOutlineSecondary:disabled,
.button.btnOutlinePrimary:disabled {
  color: #d0d0ce;
  border: 1px solid #d0d0ce;
  background-color: #ffffff !important;
}

.button.btnOutlineSecondary:hover {
  background-color: #ebebeb;
}

.button.btnOutlineSecondary:focus {
  background-color: #d0d0ce;
}

.button.link {
  font-weight: 600;
}

.button.link:hover {
  background-color: #ebf8fd;
}

.button.link:focus {
  background-color: #d6f0fa;
}

.button.thin {
  height: 2rem;
  padding: 0.2rem 1rem;
}

.button.withIcon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0.2rem 1.1rem 0.2rem 0.2rem;
}

.button .icon {
  margin-right: 5px;
}

.transparentButton {
  background-color: transparent;
  border: 0;
}
