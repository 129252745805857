.clearIndicator {
  color: #007CB0;
  font-size: 24px;
  padding-right: 10px;
  cursor: pointer;
}

.placeholder {
  display: flex;
  font-size: 14px;
}

.placeholder > div {
  color: #757575; 
}
.placeholder span {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.customSelect div[class$='control'] {
  cursor: pointer;
}

.customSelect div[class$='indicatorContainer'] {
  padding: 4px;
}

.customSelect div[class$='indicatorContainer'] svg {
  width: 16px;
  height: 16px;
}

.customSelect div[class$='control'] {
  box-shadow: none;
}

.customSelect div[class$='placeholder'] {
  margin: 0;
}
