div.header {
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
}
div.body {
    padding: 1.5rem;
    display: flex;
    align-items: center;
}
div.title {
    font-size: 20px;
    line-height: 28px;
    text-transform: none;
}
div.footer {
    display: flex;
}
div.footer button.action {
    margin-left: 16px;
    margin-right: 0;
    min-width: 64px;
    height: 2rem;
    font-size: 13px;
}

div.footer button.yesaction {
    color: #000000;
    margin-left: 16px;
    margin-right: 0;
    min-width: 64px;
    height: 2rem;
    font-size: 13px;
}

.icon {
    color: #ed8b00;
}
.iconCircle {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 1.75rem;
    border: 2px solid #ed8b00;
    flex: 0 0 auto;
    align-self: flex-start;
}
.text {
    padding: 0 0 0 1.5rem;
}
.message {
    font-weight: 600;
}
.note {
    margin: 0.5rem 0 0 0;
}
