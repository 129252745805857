.card.highlighted {
  border: 1px solid #007CB0;
  background-color: #FAFDFF;
  box-shadow: 0 0 12px 0 rgba(0,0,0,0.08);
}

.noBorder {
  border-top: 1px solid transparent !important;
}

.noBorder.highlighted {
  background-color: #FAFDFF;
}