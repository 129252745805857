.submission {
  font-size: 0.75rem;
  font-weight: 600;
}

.submission .count {
  display: inline-block;
  font-size: 0.625rem;
  height: 1rem;
  line-height: 1rem;  
  min-width: 1rem;
  padding: 0 4px;
  border-radius: 2px;
  margin-left: 5px;
  background-color: #c1eeff;
}

.submission .count.active {
  background-color: #1d95c1;
}