
.point {
  position: relative;

  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  font-size: 0.875rem;

  color: #97999B;
}

.point::before {
  content: '';
  border-radius: 50%;
  align-self: center;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 2px solid #C2C2C2;
  box-sizing: border-box;
  margin: 8px 15px 0px 0px;
}
.passed.point::before{
  border: none;
}

.point::after {
  content: '';
  width: calc(100% - 15px);
  height: 2px;
  background-color: #EBEBEB;
  position: absolute;
  left: calc(50% + 1px);
  top: 15px;
}

.point:last-child::after {
  content: none;
}

.active.point::before{
  width: 16px;
  height: 16px;
  border: 4px solid #0D8390;
  background-color: #fff;
  /* margin: 8px 0px 0px 15px; */
}

.passed.point::before,
.passed.point::after {
  background: #0D8390;
  /* margin-right: -17px; */
}


.activeLabel {
  color: black;
}

.label {
  font-weight: 400;
}
.passed >.content {
  margin-left: 145px;
}
.point > .content {
  margin-right: 101px;
}
li.active > .activeStep1:last-child
{
  margin-left: 143px;
}
li.active > .activeStep2:last-child
{
  margin-left: 0px;
}
li.active.activeStep1 {
  margin-right: -45px;
}