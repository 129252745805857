.tag {
    height: 34px;
    padding: 4px 16px;
    background: #FFFFFF;
    cursor: pointer;
    border: thin solid #D0D0CE;
    display: inline-flex;
    width: fit-content;
}

.tag.active {
    background-color: #007CB0;
    color: #fff;
}

.active .label {
    background: #007CB0;
}

.active .text {
    color: #fff;
}

.text {
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-right: 8px;
    color: #007CB0;
}

.label {
    background: #EBEBEB;
    border-radius: 2px;
    padding: 0 3px;
}