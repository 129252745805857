.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
    height: 64px;
}

.tags {
    display: flex;
    justify-content: flex-start;
}

.sorting {
    display: flex;
}

.filterControls {
    display: flex;
}