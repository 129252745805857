div[class$='option'].customOption {
  cursor: pointer;
   display: flex;
   padding: 5px 6px;
   background: #ffffff;
   justify-content: space-between;
   z-index: 9999;
   width:100%;
}

div[class$='option'].customOption:active,
div[class$='option'].customOption:hover {
  background-color: #D6F0FA;
}

.customOption.selected {
  background-color: #F5FCFE;
  color: black;
}

.customOption.focused {
  background-color: #EBF8FD;
  color: black;
}

.customOption {
  display: none;
}

.customOption.selected > div > span {
  display: inline-block;
  vertical-align: text-bottom;
}

