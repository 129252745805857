.Sidebar {
    position: fixed;
    width: 400px;
    min-height: 100vh;
    right: 0px;
    top: 0px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1031;
    padding: 0!important;
}

.Overlay {
    position: fixed;
    width: calc(100% - 399px);
    min-height: 100%;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1031;
}

.Header {
    top: 0;
    /* position: absolute; */
    width: inherit;
}

.Footer{
    bottom: 0;
    position: absolute;
    width: 100%;
}