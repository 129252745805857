div.option {
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  font-size: 0.8125rem;
  display: flex;
  align-items: center;
}
div.option:active {
  background-color: #d6f0fa;
}
div.optionFocused {
  background-color: #ebf8fd;
}
div.optionSelected {
  color: #000000;
  background-color: #f5fcfe;
}
.label {
  flex: 1;
}
.tick {
  flex-grow: 0;
  flex-shrink: 0;
  color: #007CB0;
}
