.filterPanel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem 1.5rem;
}

.filters {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.tableContainer {
  margin-bottom: 0.75rem;
}
.grid {
  overflow-y: auto;
  padding-bottom: 80px;
}

.pagination {
  position: absolute;
  left: 0;
  bottom: 115px;
  right: 0;
}

.sortAndFilters {
  display: flex;
  align-items: center;
}