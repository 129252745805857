.container {
  display: flex;
  align-items: center;
  background: #ebebeb;
  margin: 0 0.25rem 0 0;
}
.container:hover{
  background: #d8d8d8;
}
.container:active{
  background: #c5c5c5;
}
.content {
  font-size: 13px;
  padding: 0 8px;
}
.remove {
  display: flex;
  width: 24px;
  height: 24px;
  min-width: 1px;
  padding: 0;
  color: black;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  cursor: pointer;
}
.remove:hover {
  background-color: #ebf8fd;
}
