.group {
  margin-bottom: 1rem;
}
.heading {
  margin-bottom: 0.75rem;
}
.title {
  margin-bottom: 0.25rem;
}
.note {
  font-size: 0.8125rem;
  line-height: 1.5rem;
  color: #53565a;
  margin-bottom: 0.25rem;
}
.link {
  display: block;
  float: right;
  margin: -28px 0 0 0;
}
