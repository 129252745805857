.container {
  padding: 0.5rem 0.5rem 0;
}

.container p {
  margin-bottom: 0.5rem;
}

.symbolsCounter {
  font-size: 0.625rem;
  text-align: right;
}

.container div[class*='form-group'] {
  margin-bottom: 0;
}

.form {
  width: 295px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.formWrap {
  min-height: 590px;
  display: flex;
  flex-direction: column;
}

.select.multiSelect div[class$='control'] {
  height: auto;
}

.formActions {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 24px 18px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.select {
  width: 100%;
}

.select div[class$='control'] {
  height: 35px;
  min-height: unset;
}
.autoheight div[class$='control'] {
  height: auto !important;
}

.select.search div[class$='control'] {
  background-repeat: no-repeat;
  background-position: 97% center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.5 0C10.0899 0 13 2.91015 13 6.5C13 8.11487 12.4111 9.59219 11.4364 10.7289L15.8536 15.1464C16.0488 15.3417 16.0488 15.6583 15.8536 15.8536C15.68 16.0271 15.4106 16.0464 15.2157 15.9114L15.1464 15.8536L10.7289 11.4364C9.59219 12.4111 8.11487 13 6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0ZM6.5 1C3.46243 1 1 3.46243 1 6.5C1 9.53757 3.46243 12 6.5 12C9.53757 12 12 9.53757 12 6.5C12 3.46243 9.53757 1 6.5 1Z' fill='%2375787B'/%3E%3C/svg%3E");
}

.button {
  margin-right: 15px;
}


.selectWrap {
  width: 100%;
  padding: 0 15px;
  margin-bottom: 16px;
}

.withPadding {
  width: 100%;
  padding: 0 15px;
}

.label {
  width: 100%;
  margin-bottom: 8px;
  text-align: left;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000;
}

.emailWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.error {
  /* padding: 0 16px; */
  color: #DA291C;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.label.required::after {
 content: '*';
 color: #DA291C;
 margin-left: 3px;
 font-weight: 600;
 font-size: 14px;
}

.retentionInput {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    width: 100%;
}

.retentionError {
  border-color: #DA291C;
}

.modifiedUserDetail {
  display: flex;
  white-space: pre-wrap;
}

.retentionLocked{
  color: #A7A8AA;
}

.sendMail{
  width: 15px;
  height: 16px;
  margin-left: 5px;
}
