.content {
    padding: 0 20px;
    margin: 20px 0px 0px 0px;
    height: calc(100vh - 335px);
    overflow-y: auto;
}

.img_div {
    height: inherit;
    align-items: center;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    flex-direction: column;
}

.img_div p {
    line-height: 24px;
    font-size: 16px;
    padding: 15px 30px;
}

.img_div p.notes{
    color: #75787B;
}

.iconEdit {
    color: #007CB0;
    vertical-align: bottom;
}

.primaryUser {
    margin: 24px 0;
    padding: 16px 20px 24px;
    background: #F5F5F5;
}

.primaryUser > p {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;
}

.outGuideCls{
    background-color: #F0F9FA;
    padding: inherit;
    padding: 8px;
    border: 1px;
    position: absolute;
    bottom: 90px;
    display: flex;
    justify-content: center;
    margin-left: 15px;
    margin-right: 15px;
}

.guideCls{
    background-color: #F0F9FA;
    padding: inherit;
    border: 1px;
    position: relative;
}

.btnChange{
    background: transparent;
    color:black;
    font-size: 15px;
    border: none;
    font-size: medium;
    position: absolute;
    top: 0px;
    right: -9px;
}

.guidanceIcon{
   position: absolute;
   top: 8px;
   left: 8px;
   font-style:bold;
}

.guiddeTxt
{
    position: relative;
    left: 25px;
}
