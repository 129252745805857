.contentWrap {
    padding: 57px 0px 0px;
    display: flex;
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    background-color: #f5f5f5;
    position: relative;
}

.footer_wrapper {
    display: flex;
    width: 100%;
    margin: 0 auto;
    background-color: #f5f5f5;
    padding: 75px 25px 25px;
    position: relative;
  }

.contentCard {
    min-height: 100%;
    width: 100%;
    background-color: #fff;
    padding: 0;
    border-radius: 2px 2px 0 0;
    box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.06);
    position: relative;
    padding-bottom: 78px;
    margin: 24px;
}

.contentCard > span {
    position: absolute;
    top: -31px;
    left: -10px;
  }

.cardHeader {
    display: flex;
    justify-content: space-between;
    padding: 20px 16px 20px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.toast.toast {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 0px 0px 20px;
    position: absolute;
    height: 40px;
    left: 0px;
    right: -1px;
    top: 50px;
    background:#EBF8FD;
    box-shadow: inset 0px -1px 0px #00A3E0;
    border-radius: 0px;
    /* max-width: 1440px; */
    max-width: 100%;
    margin: 0 35px 0 20px;
   } 

.toastBody.toastBody {
    padding: 0;
    display: flex;
    margin: 0;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 0.8125rem;
    font-weight: normal;
    line-height: 1.8462;
    color: #212529;
    text-align: left;
    background-color: #EBF8FD;
}

.crossSvg {
    position: relative;
    top: 6px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    right: 10px;
}
.toast .warningimages{
    vertical-align: middle;
    border-style: none;
    padding: 0px 7px 0px 0px;
    margin-top: 5px;
    /* width: 14px; */
    height: 14px;
}
.toastText {
    position: static;
    width: 97%;
    height: 24px;
    left: calc(50% - 1344px/2);
    top: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}

.cardHeading {
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.cardHeadingBtnGroup {
    display: flex;
    margin-left: auto;
}

.tableWrap {
    padding: 0 16px 0 16px;
}

.pagination {
    margin: 0px -16px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.footerWrap {
    position: relative;
}

.configRetention {
    background-color: #fff !important;
    color: #007CB0 !important;
    border: 1px solid #007CB0 !important;
    font-weight: 600 !important;
    margin-right: 5px;
    display: none !important;
}

.warningText {
    margin-left: 10px;
}