.theader {
    color: #75787B;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0.45rem !important;
}

.tcell {
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
.disableDelete {
    pointer-events: none;
    opacity: 0.3;
}

.name {
    width: 20%;
}
.nameTenantTile {
    width: 40%
}
.roleAllTile {
    width: 15%;
}
.role {
    width: 10%;
}

.product {
    width: 15%;
}
.country {
    width: 15%;
}

.emailAllTile {
    width: 20%
}
.email {
    width: 30%;
}
.emailTenantTile {
    width: 40%;
}


.noFound {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    letter-spacing: 0.05rem;
}

.loaderWrap {
    margin: 30px auto;
    display: flex;
    justify-content: center;
}

.svgIcon, .svgPath{
    transition: .3s all;
    cursor: pointer;
}

.svgIcon:hover .svgPath {
    fill: #007CB0;
}

.disableSortIcon {
    color: #75787B;
    opacity: .5;
}

.theader > span {
    position: relative;
    top: 4px;
    left: 5px;
}
