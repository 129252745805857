.right {
  text-align: right !important;
}

.noEvent {
  pointer-events: none;
  opacity: 0.3;
}

.taDisable{
  background-color: transparent !important;
  color: #fff !important;
}

.mipUpdateSubs {
  margin-left: -33%;
}
