div[class$='option'].customOption {
  cursor: pointer;
  background-color: transparent;
}

div[class$='option'].customOption:active {
  background-color: #D6F0FA;
}

.customOption.selected {
  background-color: #F5FCFE !important;
  color: black;
}

.customOption.focused {
  background-color: #EBF8FD;
  color: black;
}

.customOption.disabled {
  pointer-events: none;
}

div[class$='option'].customOption:hover {
  background-color: #EBF8FD !important;
}
