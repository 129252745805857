.selectContainer {
  width: 330px;
  margin-left: auto;
}

.toggleIcon {
  color: #fff;
  margin-left: 5px;
}

.option {
  display: flex;
  justify-content: space-between;
}

.selectedOption {
  display: none;
  color: #007CB0;
}

.userProducts div[class$='control'] {
  box-shadow: none;
  color: #fff;
  font-size: 14px;
  letter-spacing: .5px;
  font-weight: 600;
  background-color: rgba(0,0,0,0);
  border: none;
  flex-wrap: nowrap;
  min-height: 28px;
}

.userProducts div[class$='control'] > div:first-of-type {
  justify-content: flex-end;
  padding: 0 5px;
}

.userProducts div[class$='singleValue'] {
  color: rgb(0, 0, 0);
  text-align: right;
}

.userProducts div[class$='IndicatorsContainer'] {
  padding-right: 0;
}

.userProducts div[class$='IndicatorsContainer'] > span{
  font-size: 8px;
  color:  #007CB0;
}