.footer {
    display: flex;
    box-shadow: inset 0px 1px 0px #EBEBEB;
    padding: 16px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0px;
}

.footerButton{
    display: flex;
    justify-content: end;
    margin-left: 28px;
}

.footer div:first-child:not(.cancel) {
    flex-basis: 60%;
    color: #53565A;
    font-size: 12px;
    line-height: 16px;
}

.update_footer_div {
    flex-basis: 100% !important;
    height: inherit;
    align-items: center;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
}