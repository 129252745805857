.container {
  font-size: 13px;
}

.plansGrid {
  background: #f6f6f6;
  padding-top: 10px;
  padding-left: 20px;
}

.plansGrid div[role*='columnheader']:nth-child(2),
.plansGrid div[class*='dataRow'] > div:nth-child(2) {
  width: 80%;
}

.plansGrid div[role*='columnheader']:nth-child(3),
.plansGrid div[class*='dataRow'] > div:nth-child(3) {
  margin-left: -20%;
  width: 80%;
}

.plansGrid div[role*='columnheader']:nth-child(4),
.plansGrid div[class*='dataRow'] > div:nth-child(4) {
  width: 100%;
  margin-left: -40%;
}

.plansGrid div[role*='columnheader']:nth-child(5),
.plansGrid div[class*='dataRow'] > div:nth-child(5) {
  width: 122%;
  margin-left: -40%;
}

.plansGrid div[role*='columnheader']:nth-child(6),
.plansGrid div[class*='dataRow'] > div:nth-child(6) {
  width: 130%;
  margin-left: -20%;
}

.plansGrid div[role*='columnheader']:nth-child(7),
.plansGrid div[class*='dataRow'] > div:nth-child(7) {
  margin-left: 10%;
}

.plansGrid div[class*='dataRow'] > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 150px;
}
