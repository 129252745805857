.container {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -4px;
}
.icon {
  color: #007CB0;
}
.iconFocused {
  color: #007CB0;
}
