.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 144px;   */
}

.container.disabled {
  pointer-events: none;
}

.countButton,
.input {
  width: 32px;
  height: 32px;
  background-color: #FFFFFF;
  border: 1px solid #D0D0CE;
  outline: none;
  box-shadow: none;
}

.countButton:focus {
  outline: none;  
}

.countButton:hover {
  background-color: #fafaf6;
}

.countButton:disabled {
  background-color: #fafaf6;
  cursor: not-allowed; 
}

.input {
  width: 64px;
  text-align: center;
}

.customDisabled{
  background-color: #fafaf6;
  cursor: not-allowed; 
}
