.footer {
  grid-template-rows: 35px;
}

.footer .submitButton {
  border: none;
}

.footer .submitButton:disabled {
  color: #97999B;
  background-color: transparent !important;
}

.info {
  font-size: 12px;
  color: #97999B;
}

.centered {
  text-align: center;
}
