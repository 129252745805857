.adminContainer {
  background-color: #f5f5f5;
}

.content {
  padding: 16px 24px;
  margin: calc(50vh - 140px) auto 0;
  width: 400px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e6e6e6;
  box-shadow: 0 8px 12px 5px rgba(0,0,0,0.08);
  outline: 0;
}

.header {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 15px;
}

.title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
}

.text {
  font-weight: 400;
}