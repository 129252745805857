.wrapper {
    display: flex;
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    background-color: #E5E5E5;
    padding: 57px 25px 0px 0px;
    margin-bottom: 100px;
    position: relative;
}

.container {
    width: 100%;
    min-height: 100%;
    background-color: #fff;
    padding: 0;
    border-radius: 2px 2px 0 0;
    /* box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.06); */
    position: relative;
    /* display: flex; */
    flex: auto;
    margin: 10px -10px 10px 10px;
}
.mainText {
    position: absolute;
    top: -31px;
    left: -10px;
  }
  .dropdownaction {
    width: 430px;
    margin-left: auto;
}
.engDropdownaction {
  width: 250px;
  margin-left: auto;
}

.toggleIcon {
    color: #fff;
    margin-left: 5px;
  }
  
  .option {
    display: flex;
    justify-content: space-between;
  }
  
  .selectedOption {
    display: none;
    color: #00A3E0;
  }
  .actionLabel{
    font-weight: 600;
    float: left;
    font-size: 14px;
    /* margin-left: 20px; */
  }
  .mainTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  
  .action div[class$='control'] {
    border: none;
    margin-top: 1px;
  }
  
  .action div[class$='control'] > div:first-of-type {
    justify-content: flex-end;
    padding: 0 5px;
  }
  
  .action div[class$='singleValue'] {
    color: rgb(0, 0, 0);
    text-align: right;
  }
  
  .action div[class$='IndicatorsContainer'] {
    padding-right: 0;
  }
  
  .action div[class$='IndicatorsContainer'] > span{
    font-size: 8px;
    color:  #007CB0;
  }
  /* use to align actions with Select Plan(s) to label */
  .placeholder span {
    display: none;
    flex-direction: column;
    align-self: center;
  }


.container>main {
    min-height: 100%;
    /* padding: 0 1rem; */
    /* padding-bottom: 92px; */
}

.footer{
    padding: 0 16px;
}

.header_container{
        /* margin: 1.57rem 0 0 1.5rem; */
        padding: 20px  !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;    
        background-color: #fff !important;    
}

.header_container > h1 {
    margin: 0;
}

.border_rule{
    height: 1px;
    box-shadow: inset 0 -1px 0 0 #E1E5EB;
}

.m_b_2{
    margin-bottom: 1rem;
}

.page_padding{
    background-color: #F7F7F7;
}
.whileBackground{
    background-color: #fff;
}
.titleRow {
    padding: 20px 19px;
    max-width: 1280px;
    margin: 0 auto;
}
