.content {
  padding: 15px;
  border-bottom: 1px solid #E0E0E0;
}

.filterHeader {
  display: flex;
  align-items: center;
}

.filterHeaderCol {
  display: inline-flex !important;
}

.filterHeader button {
  font-weight: 600;
  color: #007CB0;
}

.filterSubHeaderCol {
  flex:1;
  display: inline-flex !important;
}

.filterHeader button {
  margin-left: auto;
}