.container {
  padding: 0.5rem 0.5rem 0;
}

.container p {
  margin-bottom: 0.5rem;
}

.symbolsCounter {
  font-size: 0.625rem;
  text-align: right;
}

.container div[class*='form-group'] {
  margin-bottom: 0;
}