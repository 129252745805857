div.container {
  border-radius: 0;
  min-width: 320px;
  padding: 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 67px);
}
.menuHeaderItem {
  padding: 16px;
  border-bottom: 1px solid #e5e5e5;
}
.user {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  background: #000000;
  border-radius: 100px;
}
.userInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 12px;
  line-height: 1.2;
  align-items: center;
}
.userInfoTitle {
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 2px;
  line-height: 1.2;
}
.userInfoSubTitle {
  font-size: 0.75rem;
  color: #97999b;
  font-weight: 600;
}
.menuButton {
  background-color: transparent;
  border-width: 0;
  width: 100%;
  display: block;
  margin: 0;
  text-align: left;
  padding: 16px 16px;
  display: flex;
  align-items: center;
  color: #000000;
}
.menuButton:hover {
  background-color: #ebebeb;
}
.menuButton::after {
  display: none !important;
}
.profileSettings {
  font-size: 16px;
  border-bottom: 1px solid #e5e5e5;
}
.signOut {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.signOutIcon {
  margin-right: 12px;
}
.selectLangTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 1.2;
  color: #757575;
}
.fallbackTxt {
  font-size: 12px;
  line-height: 1.2;
  margin-top: 8px;
}