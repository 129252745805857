@font-face {
  font-family: 'deloitte-special_font-v2';
  src: url('../fonts/deloitte-special_font-v2/deloitte-special_font-v2.eot?wlg7ms');
  src: url('../fonts/deloitte-special_font-v2/deloitte-special_font-v2.eot?wlg7ms#iefix')
      format('embedded-opentype'),
    url('../fonts/deloitte-special_font-v2/deloitte-special_font-v2.ttf?wlg7ms')
      format('truetype'),
    url('../fonts/deloitte-special_font-v2/deloitte-special_font-v2.woff?wlg7ms')
      format('woff'),
    url('../fonts/deloitte-special_font-v2/deloitte-special_font-v2.svg?wlg7ms#deloitte-special_font-v2')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face{
  font-family: 'OpenSans-Bold';
  src: url('../fonts/OpenSans-Bold.ttf'),
       url('../fonts/OpenSans-Bold.woff');
}
@font-face{
  font-family: 'OpenSans-Regular';
  src: url('../fonts/OpenSans-Regular.ttf'), url('../fonts/OpenSans-Regular.woff');
}
@font-face{
  font-family: 'OpenSans-Semibold';
  src: url('../fonts/OpenSans-Semibold.ttf'), url('../fonts/OpenSans-Semibold.woff');
}
[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'deloitte-special_font-v2' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alarm-clock-special:before {
  content: '\e9bd';
}
.icon-archive-box-special:before {
  content: '\e9be';
}
.icon-arrow-double-down-special:before {
  content: '\e9bf';
}
.icon-arrow-double-left-special:before {
  content: '\e9c0';
}
.icon-arrow-double-right-special:before {
  content: '\e9c1';
}
.icon-arrow-double-up-special:before {
  content: '\e9c2';
}
.icon-arrow-down-special:before {
  content: '\e9c3';
}
.icon-arrow-left-right-special:before {
  content: '\e900';
}
.icon-arrow-left-special:before {
  content: '\e901';
}
.icon-arrow-right-special:before {
  content: '\e902';
}
.icon-arrow-up-down-special:before {
  content: '\e903';
}
.icon-arrow-up-special:before {
  content: '\e904';
}
.icon-backup-complete-special:before {
  content: '\e905';
}
.icon-backup-special:before {
  content: '\e906';
}
.icon-bar-chart-1-special:before {
  content: '\e907';
}
.icon-bar-chart-2-special:before {
  content: '\e908';
}
.icon-battery-0-special:before {
  content: '\e909';
}
.icon-battery-1-special:before {
  content: '\e90a';
}
.icon-battery-5-special:before {
  content: '\e9c4';
}
.icon-bin-special:before {
  content: '\e9c5';
}
.icon-blog-special:before {
  content: '\e9c6';
}
.icon-bluetooth-special:before {
  content: '\e90b';
}
.icon-book-open-special:before {
  content: '\e90c';
}
.icon-bookmark-2-special:before {
  content: '\e90d';
}
.icon-bookmark-special:before {
  content: '\e90e';
}
.icon-briefcase-special:before {
  content: '\e90f';
}
.icon-broadcast-special:before {
  content: '\e910';
}
.icon-bug-special:before {
  content: '\e911';
}
.icon-burger-menu-special:before {
  content: '\e912';
}
.icon-calender-special:before {
  content: '\e9c7';
}
.icon-camera-special:before {
  content: '\e9c8';
}
.icon-checkbox-empty-special:before {
  content: '\e9c9';
}
.icon-checkbox-full-special:before {
  content: '\e913';
}
.icon-checkbox-tick-special:before {
  content: '\e914';
}
.icon-claperboard-special:before {
  content: '\e915';
}
.icon-clipboard-special:before {
  content: '\e916';
}
.icon-clock-special:before {
  content: '\e917';
}
.icon-cloud-special:before {
  content: '\e918';
}
.icon-code-special:before {
  content: '\e919';
}
.icon-cog-special:before {
  content: '\e91a';
}
.icon-cogs-special:before {
  content: '\e9ca';
}
.icon-commenting-special:before {
  content: '\e9cb';
}
.icon-compass-1-special:before {
  content: '\e9cc';
}
.icon-credit-card-special:before {
  content: '\e91b';
}
.icon-crop-special:before {
  content: '\e91c';
}
.icon-cross-special:before {
  content: '\e91d';
}
.icon-cursor-special:before {
  content: '\e91e';
}
.icon-decrease-4-special:before {
  content: '\e91f';
}
.icon-decrease-5-special:before {
  content: '\e920';
}
.icon-direction-special:before {
  content: '\e921';
}
.icon-dislike-special:before {
  content: '\e922';
}
.icon-display-special:before {
  content: '\e9cd';
}
.icon-document-1-special:before {
  content: '\e9ce';
}
.icon-document-2-special:before {
  content: '\e9cf';
}
.icon-document-3-special:before {
  content: '\e923';
}
.icon-download-from-cloud-special:before {
  content: '\e924';
}
.icon-download-special:before {
  content: '\e925';
}
.icon-edit-profile-special:before {
  content: '\e926';
}
.icon-ellipsis-special:before {
  content: '\e927';
}
.icon-error-special:before {
  content: '\e928';
}
.icon-exclamation-mark-special:before {
  content: '\e929';
}
.icon-exit-special:before {
  content: '\e92a';
}
.icon-external-link-special:before {
  content: '\e92b';
}
.icon-eye-special:before {
  content: '\e92c';
}
.icon-fast-forward-special:before {
  content: '\e92d';
}
.icon-file-audio-1-special:before {
  content: '\e92e';
}
.icon-file-code-special:before {
  content: '\e92f';
}
.icon-file-doc-special:before {
  content: '\e930';
}
.icon-file-edit-special:before {
  content: '\e931';
}
.icon-file-movie-special:before {
  content: '\e932';
}
.icon-file-new-special:before {
  content: '\e933';
}
.icon-file-pdf-special:before {
  content: '\e934';
}
.icon-file-picture-special:before {
  content: '\e935';
}
.icon-file-ppt-special:before {
  content: '\e936';
}
.icon-file-xls-special:before {
  content: '\e937';
}
.icon-file-zip-special:before {
  content: '\e938';
}
.icon-filter-special:before {
  content: '\e939';
}
.icon-flag-special:before {
  content: '\e93a';
}
.icon-folder-special:before {
  content: '\e93b';
}
.icon-folders-special:before {
  content: '\e93c';
}
.icon-forward-special:before {
  content: '\e93d';
}
.icon-globe-special:before {
  content: '\e93e';
}
.icon-grid-special:before {
  content: '\e93f';
}
.icon-headphones-special:before {
  content: '\e940';
}
.icon-heart-special:before {
  content: '\e941';
}
.icon-history-special:before {
  content: '\e942';
}
.icon-house-special:before {
  content: '\e943';
}
.icon-inbox-special:before {
  content: '\e944';
}
.icon-increase-5-special:before {
  content: '\e945';
}
.icon-infinity-loop-special:before {
  content: '\e946';
}
.icon-key-2-special:before {
  content: '\e947';
}
.icon-laptop-special:before {
  content: '\e948';
}
.icon-life-buoy-special:before {
  content: '\e949';
}
.icon-lightbulb-special:before {
  content: '\e94a';
}
.icon-like-special:before {
  content: '\e94b';
}
.icon-link-special:before {
  content: '\e94c';
}
.icon-list-2-special:before {
  content: '\e94d';
}
.icon-list-special:before {
  content: '\e94e';
}
.icon-lock-special:before {
  content: '\e94f';
}
.icon-mail-in-special:before {
  content: '\e950';
}
.icon-mail-out-special:before {
  content: '\e951';
}
.icon-mail-special:before {
  content: '\e952';
}
.icon-male-symbol-special:before {
  content: '\e953';
}
.icon-man-silhouette-special:before {
  content: '\e954';
}
.icon-map-special:before {
  content: '\e955';
}
.icon-microphone-2-special:before {
  content: '\e956';
}
.icon-microphone-3-special:before {
  content: '\e957';
}
.icon-minus-special:before {
  content: '\e958';
}
.icon-mobile-special:before {
  content: '\e959';
}
.icon-movie-clip-special:before {
  content: '\e95a';
}
.icon-music-special:before {
  content: '\e95b';
}
.icon-navigation-1-special:before {
  content: '\e95c';
}
.icon-navigation-2-special:before {
  content: '\e95d';
}
.icon-newspaper-special:before {
  content: '\e95e';
}
.icon-no-entry-special:before {
  content: '\e95f';
}
.icon-non-visable-special:before {
  content: '\e960';
}
.icon-office-locator-special:before {
  content: '\e961';
}
.icon-open-folder-special:before {
  content: '\e962';
}
.icon-open-folder-special:before {
  content: '\e963';
}
.icon-options-special:before {
  content: '\e964';
}
.icon-org-chart-special:before {
  content: '\e965';
}
.icon-paper-plane-special:before {
  content: '\e966';
}
.icon-paperclip-special:before {
  content: '\e967';
}
.icon-partlyselected-checkbox:before {
  content: '\e968';
}
.icon-phone-special:before {
  content: '\e969';
}
.icon-picture-special:before {
  content: '\e96a';
}
.icon-pie-chart-1-special:before {
  content: '\e96b';
}
.icon-pin-special:before {
  content: '\e96c';
}
.icon-plane-1-special:before {
  content: '\e96d';
}
.icon-play-special:before {
  content: '\e96e';
}
.icon-plus-special:before {
  content: '\e96f';
}
.icon-podcast-special:before {
  content: '\e970';
}
.icon-power-special:before {
  content: '\e971';
}
.icon-printer-special:before {
  content: '\e972';
}
.icon-profile-2-special:before {
  content: '\e973';
}
.icon-profile-special:before {
  content: '\e974';
}
.icon-public-building-special:before {
  content: '\e975';
}
.icon-qr-code-special:before {
  content: '\e976';
}
.icon-question-mark-special:before {
  content: '\e977';
}
.icon-quote-marks-2-special:before {
  content: '\e978';
}
.icon-quote-marks-special:before {
  content: '\e979';
}
.icon-record-special:before {
  content: '\e97a';
}
.icon-recycle-special:before {
  content: '\e97b';
}
.icon-refresh-special:before {
  content: '\e97c';
}
.icon-reply-all-special:before {
  content: '\e97d';
}
.icon-reply-special:before {
  content: '\e97e';
}
.icon-return-special:before {
  content: '\e97f';
}
.icon-rewind-special:before {
  content: '\e980';
}
.icon-rss-special:before {
  content: '\e981';
}
.icon-satchel-special:before {
  content: '\e982';
}
.icon-scissors-special:before {
  content: '\e983';
}
.icon-search-special:before {
  content: '\e984';
}
.icon-selected-checkbox:before {
  content: '\e985';
}
.icon-selected-radiobutton:before {
  content: '\e986';
}
.icon-share-special:before {
  content: '\e987';
}
.icon-shield-special:before {
  content: '\e988';
}
.icon-shuffle-special:before {
  content: '\e989';
}
.icon-simple-arrow-left-special:before {
  content: '\e98a';
}
.icon-simple-arrow-right-special:before {
  content: '\e98b';
}
.icon-simple-arrow-up-special:before {
  content: '\e98c';
}
.icon-skip-backwards-special:before {
  content: '\e98d';
}
.icon-skip-forward-special:before {
  content: '\e98e';
}
.icon-skip-forwards-special:before {
  content: '\e98f';
}
.icon-skip-rewind-special:before {
  content: '\e990';
}
.icon-slider-special:before {
  content: '\e991';
}
.icon-smallchevron-down:before {
  content: '\e992';
}
.icon-smallchevron-left:before {
  content: '\e993';
}
.icon-smallchevron-right:before {
  content: '\e994';
}
.icon-smallchevron-up:before {
  content: '\e995';
}
.icon-sort-alpha-ascending-special:before {
  content: '\e996';
}
.icon-sort-alpha-descending-special:before {
  content: '\e997';
}
.icon-sort-amount-ascending-special:before {
  content: '\e998';
  transform: rotateX(180deg);
  display: inline-block;
}
.icon-sort-amount-descending-special:before {
  content: '\e999';
}
.icon-speech-2-special:before {
  content: '\e99a';
}
.icon-speech-bubble-special:before {
  content: '\e99b';
}
.icon-speedometer-special:before {
  content: '\e99c';
}
.icon-spin-special:before {
  content: '\e99d';
}
.icon-stack-overflow-special:before {
  content: '\e99e';
}
.icon-star-special:before {
  content: '\e99f';
}
.icon-stop-special:before {
  content: '\e9a0';
}
.icon-stopwatch-special:before {
  content: '\e9a1';
}
.icon-sun-shade-special:before {
  content: '\e9a2';
}
.icon-sun-special:before {
  content: '\e9a3';
}
.icon-tablet-special:before {
  content: '\e9a4';
}
.icon-tag-special:before {
  content: '\e9a5';
}
.icon-television-special:before {
  content: '\e9a6';
}
.icon-tick-list-special:before {
  content: '\e9a7';
}
.icon-tick-special:before {
  content: '\e9a8';
}
.icon-toolbox-special:before {
  content: '\e9a9';
}
.icon-traffic-signal-eu-special:before {
  content: '\e9aa';
}
.icon-train-special:before {
  content: '\e9ab';
}
.icon-trolley-special:before {
  content: '\e9ac';
}
.icon-trophy-special:before {
  content: '\e9ad';
}
.icon-unlink-special:before {
  content: '\e9ae';
}
.icon-unlock-special:before {
  content: '\e9af';
}
.icon-unselected-checkbox:before {
  content: '\e9b0';
}
.icon-unselected-radiobutton:before {
  content: '\e9b1';
}
.icon-upload-special:before {
  content: '\e9b2';
}
.icon-upload-to-cloud-special:before {
  content: '\e9b3';
}
.icon-usb-special:before {
  content: '\e9b4';
}
.icon-user-2-special:before {
  content: '\e9b5';
}
.icon-video-special:before {
  content: '\e9b6';
}
.icon-volume-1-special:before {
  content: '\e9b7';
}
.icon-volume-4-special:before {
  content: '\e9b8';
}
.icon-wifi-special:before {
  content: '\e9b9';
}
.icon-zoom-in-special:before {
  content: '\e9ba';
}
.icon-zoom-out-special:before {
  content: '\e9bb';
}
