.button.button {
    color: #007CB0;
    padding: 0.3rem 0.5rem;
    border: none;
    background-color: transparent;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    min-width: unset;
}

.button.button:hover {
    background: #D6EAF2;
    color: #007CB0;
}

.button.button:not(:disabled):not(.disabled):active {
    background-color: #D6EAF2;
    color: #007CB0;
}

.button.button.filled {
    background-color: #007CB0;
    color: #fff;
    padding: 4px 16px;
}