.wrapper {
  position: relative;
  min-width: 1000px;
  max-width: 100%;
  /* margin: 0 auto; */
  /* below css updated to add margin in registration token page  */
  margin: 0 30px;
  min-height: 100%;
  height: auto;
}

.content {
  padding: 48px 0 180px;
}
