div.control {
  border-radius: 0;
  max-height: 100%;
  min-height: 100%;
  box-shadow: none;
}

div.control:hover {
  border-color: #007CB0;
}

div.controlFocused {
  border-color: #007CB0;
}

div.controlMultiple {
  padding-left: 3px;
  height: fit-content;
  max-height: 32px;
  overflow: auto;
}

div.controlMultiple > div{
  height: fit-content;
}

div.control:active {
  background-color: #ebf8fd;
}

div.control > div {
  padding: 0;
  /* max-height: 100%; */
  min-height: 100%;
  align-self: stretch;
}

div.control:hover .dropdownIndicatorIcon,
div.controlFocused .dropdownIndicatorIcon {
  color: #007CB0;
}

div.control div[class*='-Input'] {
  margin: 0;
  min-height: inherit;
  max-height: inherit;
}
