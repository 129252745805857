div.header {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
}
div.body {
  padding: 1.5rem;
  display: flex;
  align-items: center;
}
div.title {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
div.footer {
  display: flex;
}
div.footer button.action {
  margin-left: 16px;
  margin-right: 0;
  min-width: 64px;
}

.icon {
  color: #ed8b00;
}
.iconCircle {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 1.75rem;
  border: 2px solid #ed8b00;
  flex: 0 0 auto;
  align-self: flex-start;
}
.text {
  padding: 0 0 0 0;
}
.message {
  font-weight: 600;
}
.note {
  margin: 0.5rem 0 0 0;
}
.cancelAction{
  width: 86px;
  border: 1px solid #007CB0 !important;
  box-sizing: border-box;
  border-radius: 2px;
  color: #007CB0 !important;
  background: #ffffff !important;
}
.modal .confirmatiocancelAction{
  width: 86px;
  box-sizing: border-box;
  border-radius: 2px;
  color: #ffffff;
  background: #DA291C;
  }
.continueAction{
  width: 105px;
}
.warningImage{
  margin-right: 12px;
}
.warningImage img {
  margin-top:-2px;
}
.yesAction{
  width: 105px;
  background-color: red;
}