.initial_div {
    display: flex;
    min-width: 32px;
    width: 32px;
    height: 32px;
    background-color: #53565a;
    color: #fff;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    line-height: 24px;
}

.content_div {
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.disabled_div {
    pointer-events: none;
    opacity: 0.3;
}

.content_div:first-child {
    margin-top: 0;
}

.content_div:last-child {
    margin-bottom: 0;
}

.name {
    font-size: 12px;
    line-height: 1;
}

.name span:first-child {
    display: block;
    line-height: 1;
    font-weight: 600;
    margin-bottom: 4px;
}

.name span:last-child {
    color: #75787B;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.iconButton {
    border: none;
    background-color: transparent;
}

.iconButton:focus {
    outline: none;
}