.footer {
  margin: -101px auto 10px;
  max-width: 100%;
  height: 92px;
  position: relative;
  box-shadow: inset 0 1px 0 0 #e1e5eb;
  padding: 0 10px;
  color: #53565a;
  background-color: #fff;
  line-height: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #F5F5F5;
  padding: 48px;
}
.footer a {
  color: #007CB0 !important;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
 }

.detailDialog div[class$='body'] {
  padding: 10px 10px 20px 24px;
}

.textBold{
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  color: #000;
  padding-bottom: 10px;
}
.newtabIcon_space{
  margin-left: 10px;
}