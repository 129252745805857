.buttonPanel {
  position: absolute;
  bottom: 92px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  box-shadow: inset 0 1px 0 0 #EBEBEB;
  padding: 27px 20px;
}

.buttonPanel > button:nth-of-type(2) {
  margin-left: auto;
  margin-right: 15px;
}
.leftArrow {
  padding-right: 10px;
}
.rightAlignment{
  margin-right:15px;
}
