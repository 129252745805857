.container {
  padding: 0.5rem 0.5rem 0;
}

.container p {
  margin-bottom: 0.5rem;
}

.symbolsCounter {
  font-size: 0.625rem;
  text-align: right;
}

.container div[class*='form-group'] {
  margin-bottom: 0;
}
.cancelAction{
  width: 86px;
  border: 1px solid #007CB0 !important;
  box-sizing: border-box;
  border-radius: 2px;
  color: #007CB0 !important;
  background: #ffffff !important;
}
.continueAction{
  width: 105px;
}