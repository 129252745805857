$blue: #007CB0;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #da291c;
$orange: #ed8b00;
$yellow: #ffc107;
$green: #009a44;
$teal: #43b02a;
$cyan: #17a2b8;
$white: #fff;
$gray-100: #f5f5f5;
$gray-200: #ebebeb;
$gray-300: #97999b;
$gray-400: #75787b;
$gray-500: #63666a;
$gray-600: #53565a;
$gray-700: #495057;
$gray-800: #041e42;
$gray-900: #212529;
$black: #000;
$theme-color-interval: 8%;
$spacer: 1rem;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;
$body-bg: $white;
$body-color: $gray-900;
$link-color: theme-color('primary');
$link-decoration: none;
$link-hover-color: #0095cd;
$link-hover-decoration: none;
$grid-columns: 12;
$grid-gutter-width: 1.875rem;
$line-height-lg: 1.5;
$line-height-sm: 1.5;
$border-width: 0.0625rem;
$border-radius: 0.125rem;
$border-radius-lg: 0.3rem;
$border-radius-sm: 0.2rem;
$component-active-color: $white;
$component-active-bg: theme-color('primary');
$caret-width: 0.3em;
$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;
$font-family-sans-serif: 'Open Sans', Arial, sans-serif;
$font-family-monospace: Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace;
$font-family-base: $font-family-sans-serif;
$font-size-base: 0.8125rem;
$font-size-lg: 2rem;
$font-size-sm: 0.63rem;
$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.8462;
$h1-font-size: 1.125rem;
$h2-font-size: 1rem;
$h3-font-size: 0.88rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;
$headings-margin-bottom: ($spacer / 2);
$headings-font-family: inherit;
$headings-font-weight: 600;
$headings-line-height: 1.5;
$headings-color: inherit;
$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;
$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$display-line-height: $headings-line-height;
$lead-font-size: 1rem;
$lead-font-weight: 600;
$small-font-size: 80%;
$text-muted: $gray-600;
$blockquote-small-color: $gray-600;
$blockquote-font-size: ($font-size-base * 1.25);
$hr-border-color: $gray-200;
$hr-border-width: $border-width;
$mark-padding: 0.2em;
$dt-font-weight: $font-weight-bold;
$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25);
$nested-kbd-font-weight: $font-weight-bold;
$list-inline-padding: 0.3125rem;
$mark-bg: #fcf8e3;
$btn-padding-y: 0.5rem;
$btn-padding-x: 1rem;
$input-btn-padding-y: 0.2%;
$input-btn-padding-x: 0.5rem;
$input-btn-line-height: 1.8462;
$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-line-height-sm: 1.5;
$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-line-height-lg: 1.5;
$btn-font-weight: $font-weight-normal;
$btn-box-shadow: inset 0 0.0625rem 0 rgba($white, 0.15), 0 0.0625rem 0.0625rem rgba($black, 0.075);
$btn-focus-box-shadow: none;
$btn-active-box-shadow: inset 0 0.1875rem 0.3125rem rgba($black, 0.125);
$btn-link-disabled-color: #d0d0ce;
$btn-block-spacing-y: 0.5rem;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: $border-radius-sm;
$btn-transition: all 0.15s ease-in-out;
$input-bg: $white;
$input-disabled-bg: rgba(0, 0, 0, 0.04);
$input-color: $black;
$input-border-color: #d0d0ce;
$input-btn-border-width: $border-width;
$input-box-shadow: inset 0 0.0625rem 0.0625rem rgba($black, 0.075);
$input-border-radius: 0;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;
$input-focus-bg: $input-bg;
$input-focus-border-color: theme-color('primary');
$input-focus-box-shadow: $input-box-shadow, $btn-focus-box-shadow;
$input-focus-color: $input-color;
$input-placeholder-color: $gray-600;
$input-height-border: $input-btn-border-width;
$input-height-inner: 1.5rem;
$input-height: 2rem;
$input-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
$form-text-margin-top: 0.1rem;
$form-check-margin-bottom: 0.5rem;
$form-check-input-gutter: 1.25rem;
$form-check-input-margin-y: 0.25rem;
$form-check-input-margin-x: 0.25rem;
$form-check-inline-margin-x: 0.75rem;
$form-group-margin-bottom: 1rem;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;
$custom-control-gutter: 1.5rem;
$custom-control-spacer-y: 0.25rem;
$custom-control-spacer-x: 1rem;
$custom-control-indicator-size: 1rem;
$custom-control-indicator-bg: #ddd;
$custom-control-indicator-bg-size: 50% 50%;
$custom-control-indicator-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1);
$custom-control-indicator-disabled-bg: $gray-200;
$custom-control-description-disabled-color: $gray-600;
$custom-control-indicator-checked-color: $white;
$custom-control-indicator-checked-bg: theme-color('primary');
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-focus-box-shadow: 0 0 0 0.0625rem $body-bg,
  0 0 0 0.1875rem theme-color('primary');
$custom-control-indicator-active-color: $white;
$custom-control-indicator-active-bg: lighten(theme-color('primary'), 35%);
$custom-control-indicator-active-box-shadow: none;
$custom-checkbox-indicator-border-radius: $border-radius;
$custom-checkbox-indicator-indeterminate-bg: theme-color('primary');
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-indeterminate-box-shadow: none;
$custom-radio-indicator-border-radius: 50%;
$custom-select-padding-y: 0.1rem;
$custom-select-padding-x: 0.75rem;
$custom-select-height: $input-height;
$custom-select-indicator-padding: 1rem;
$custom-select-line-height: $input-btn-line-height;
$custom-select-color: $input-color;
$custom-select-disabled-color: $gray-600;
$custom-select-bg: $white;
$custom-select-disabled-bg: $gray-200;
$custom-select-bg-size: 0.5rem 0.625rem;
$custom-select-indicator-color: #333;
$custom-select-border-width: $input-btn-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-focus-border-color: lighten(theme-color('primary'), 25%);
$custom-select-focus-box-shadow: inset 0 0.0625rem 0.125rem rgba($black, 0.075),
  0 0 0.3125rem rgba($custom-select-focus-border-color, 0.5);
$custom-select-font-size-sm: 75%;
$custom-file-height: 2.5rem;
$custom-file-width: 14rem;
$custom-file-focus-box-shadow: 0 0 0 0.075rem $white,
  0 0 0 0.2rem theme-color('primary');
$custom-file-padding-y: 1rem;
$custom-file-padding-x: 0.5rem;
$custom-file-line-height: 1.5;
$custom-file-color: $gray-700;
$custom-file-bg: $white;
$custom-file-border-width: $border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $border-radius;
$custom-file-box-shadow: inset 0 0.2rem 0.4rem rgba($black, 0.05);
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $gray-200;
$form-feedback-valid-color: theme-color('success');
$form-feedback-invalid-color: theme-color('danger');
$dropdown-min-width: 10.375rem;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.125rem;
$dropdown-bg: $white;
$dropdown-border-color: $gray-200;
$dropdown-border-width: 0.0625rem;
$dropdown-divider-bg: $gray-200;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.04);
$dropdown-link-color: $black;
$dropdown-link-hover-color: $black;
$dropdown-link-hover-bg: #ebf8fd;
$dropdown-link-active-color: $black;
$dropdown-link-active-bg: #d6f0fa;
$dropdown-link-disabled-color: $gray-600;
$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 1rem;
$dropdown-header-color: $gray-600;
$nav-link-padding-y: 0rem;
$nav-link-disabled-color: $gray-600;
$nav-tabs-border-color: #ddd;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-200;
$nav-tabs-link-active-color: $gray-700;
$nav-tabs-link-active-bg: $body-bg;
$nav-tabs-link-active-border-color: #ddd;
$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;
$navbar-padding-x: $spacer / 2;
$navbar-brand-font-size: $font-size-lg / 2;
$nav-link-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-height: (
  $font-size-base * $line-height-base + $nav-link-padding-y * 2
);
$navbar-brand-padding-y: ($navbar-brand-height - $nav-link-height) / 2;
$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;
$navbar-dark-color: rgba($white, 0.5);
$navbar-dark-hover-color: rgba($white, 0.75);
$navbar-dark-active-color: rgba($white, 1);
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-border-color: rgba($white, 0.1);
$navbar-light-color: rgba($black, 0.5);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-active-color: rgba($black, 0.9);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-border-color: rgba($black, 0.1);
$table-cell-padding: 0.75rem;
$table-cell-padding-sm: 0.3rem;
$table-bg: transparent;
$table-accent-bg: rgba($black, 0.05);
$table-hover-bg: rgba($black, 0.075);
$table-active-bg: $table-hover-bg;
$table-border-width: $border-width;
$table-border-color: $gray-200;
$table-head-bg: $gray-200;
$table-head-color: $gray-700;
$table-inverse-bg: $gray-900;
$table-inverse-accent-bg: rgba($white, 0.05);
$table-inverse-hover-bg: rgba($white, 0.075);
$table-inverse-border-color: lighten($gray-900, 7.5%);
$table-inverse-color: $body-bg;
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$pagination-padding-y: 0.3rem;
$pagination-padding-x: 0.536rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;
$pagination-line-height: 1.25;
$pagination-color: $blue;
$pagination-bg: $white;
$pagination-border-width: 0;
$pagination-border-color: $blue;
$pagination-hover-color: $blue;
$pagination-hover-bg: transparent;
$pagination-hover-border-color: transparent;
$pagination-active-color: $black;
$pagination-active-bg: transparent;
$pagination-active-border-color: theme-color('primary');
$pagination-disabled-color: $blue;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $blue;
$jumbotron-padding: 2rem;
$jumbotron-bg: $gray-200;
$card-spacer-y: 2rem;
$card-spacer-x: 2rem;
$card-border-width: 0.0625rem;
$card-border-radius: 0;
$card-border-color: #e6e6e6;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: $white;
$card-bg: $white;
$card-img-overlay-padding: 1.25rem;
$card-deck-margin: ($grid-gutter-width / 2);
$card-columns-count: 3;
$card-columns-gap: 1.25rem;
$card-columns-margin: $card-spacer-y;
$tooltip-max-width: 12.5rem;
$tooltip-color: $white;
$tooltip-bg: $black;
$tooltip-opacity: 0.9;
$tooltip-padding-y: 0.1875rem;
$tooltip-padding-x: 0.5rem;
$tooltip-margin: 0;
$tooltip-arrow-width: 0.3125rem;
$tooltip-arrow-height: 0.3125rem;
$tooltip-arrow-color: $tooltip-bg;
$popover-inner-padding: 0.0625rem;
$popover-bg: $white;
$popover-max-width: 17.25rem;
$popover-border-width: $border-width;
$popover-border-color: rgba($black, 0.2);
$popover-box-shadow: 0 0.3125rem 0.625rem rgba($black, 0.2);
$popover-header-bg: darken($popover-bg, 3%);
$popover-header-color: $headings-color;
$popover-header-padding-y: 0.5rem;
$popover-header-padding-x: 0.875rem;
$popover-body-color: $body-color;
$popover-body-padding-y: 0.5625rem;
$popover-body-padding-x: 0.875rem;
$popover-arrow-width: 0.625rem;
$popover-arrow-height: 0.3125rem;
$popover-arrow-color: $popover-bg;
$popover-arrow-outer-width: ($popover-arrow-width + 0.0625rem);
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);
$badge-color: $white;
$badge-font-size: 75%;
$badge-font-weight: $font-weight-bold;
$badge-padding-y: 0.25em;
$badge-padding-x: 0.4em;
$badge-pill-padding-x: 0.6em;
$badge-pill-border-radius: 10rem;
$modal-inner-padding: 1rem;
$modal-dialog-margin: 0.625rem;
$modal-dialog-margin-y-sm-up: 1.875rem;
$modal-title-line-height: $line-height-base;
$modal-content-bg: $white;
$modal-content-border-color: rgba($black, 0.2);
$modal-content-border-width: $border-width;
$modal-content-box-shadow-xs: 0 0.1875rem 0.5625rem rgba($black, 0.5);
$modal-content-box-shadow-sm-up: 0 0.3125rem 0.9375rem rgba($black, 0.5);
$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;
$modal-header-border-color: $gray-200;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding: 0.9375rem;
$modal-lg: 50rem;
$modal-md: 31.25rem;
$modal-sm: 18.75rem;
$modal-transition: transform 0.3s ease-out;
$alert-padding-y: 0.75rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;
$progress-height: 1rem;
$progress-font-size: 0.75rem;
$progress-bg: $gray-200;
$progress-border-radius: $border-radius;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1);
$progress-bar-color: $white;
$progress-bar-bg: theme-color('primary');
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.6s ease;
$list-group-bg: $white;
$list-group-border-color: rgba($black, 0.125);
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;
$list-group-item-padding-y: 0.75rem;
$list-group-item-padding-x: 1.25rem;
$list-group-hover-bg: $gray-100;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;
$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: $list-group-bg;
$list-group-action-color: $gray-700;
$list-group-action-hover-color: $list-group-action-color;
$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $gray-200;
$thumbnail-padding: 0.25rem;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: $border-width;
$thumbnail-border-color: #ddd;
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: 0 0.0625rem 0.125rem rgba($black, 0.075);
$thumbnail-transition: all 0.2s ease-in-out;
$figure-caption-font-size: 90%;
$figure-caption-color: $gray-600;
$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 1rem;
$breadcrumb-item-padding: 0.5rem;
$breadcrumb-bg: $gray-200;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-600;
$breadcrumb-divider: '/';
$carousel-control-color: $white;
$carousel-control-width: 15%;
$carousel-control-opacity: 0.5;
$carousel-indicator-width: 1.875rem;
$carousel-indicator-height: 0.1875rem;
$carousel-indicator-spacer: 0.1875rem;
$carousel-indicator-active-bg: $white;
$carousel-caption-width: 70%;
$carousel-caption-color: $white;
$carousel-control-icon-width: 1.25rem;
$carousel-transition: transform 0.6s ease;
$close-font-size: $font-size-base * 1.5;
$close-font-weight: $font-weight-bold;
$close-color: $black;
$close-text-shadow: 0 0.0625rem 0 $white;
$code-font-size: 90%;
$code-padding-y: 0.2rem;
$code-padding-x: 0.4rem;
$code-color: #bd4147;
$code-bg: $gray-100;
$kbd-color: $white;
$kbd-bg: $gray-900;
$pre-color: $gray-900;
$pre-scrollable-max-height: 21.25rem;
$navbar-padding-y: $spacer * 0.6;
$navbar-nav-link-padding-x: 1rem;
$label-margin-bottom: 0.375rem;
