.footer {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  margin-top: -1rem;
}
.button {
  min-width: 1px;
}
.button > span {
  display: flex;
  align-items: center;
}
.button + .button {
  margin-left: 24px;
}

.addFilter.button:disabled {
  background-color: transparent!important;
  opacity: 1 !important;
  border-color: transparent !important;
  color: #C2C2C2 !important;
}

.apply.button:disabled {
  background-color: #F0F0F0 !important;
  opacity: 1 !important;
  border-color: #F0F0F0 !important;
  color: #C2C2C2 !important;
}
