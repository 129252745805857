.header{
  min-height: 72px;
  /* width: inherit; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  /* border-bottom: 1px solid; */
  box-shadow: inset 0px -1px 0px #EBEBEB;
}

.header > div > h6 {
    /* line-height: 1; */
    margin-bottom: 0.1rem;
}

.header > div > p{
line-height: 16px;
font-size: 12px;
color: #53565A;
overflow-wrap: break-word;
word-wrap: break-word;
-ms-word-break: break-all;
word-break: break-all;
word-break: break-word;
-ms-hyphens: auto;
-moz-hyphens: auto;
-webkit-hyphens: auto;
hyphens: auto;
}

.iconButton {
    border: none;
    background-color: transparent;
}

.iconButton:focus {
    outline: none;
}

.noScroll {
    overflow: hidden;
}

.content {
    top: 72px;
    /* position: absolute; */
    padding: 0 20px;
    width: inherit;
    margin: 20px 0;
    height: calc(100vh - 220px);
    overflow: auto;
}

.footer {
    display: flex;
    /* height: 72px; */
    box-shadow: inset 0px 1px 0px #EBEBEB;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
}

.footer div:first-child:not(.cancel) {
    flex-basis: 71%;
    color: #53565A;
    font-size: 12px;
}

.footer button {
    padding: 3px 12px;
}

.footer button:disabled {
    background-color: #d0d0ce !important;
    opacity: 1 !important;
    border-color: #d0d0ce !important;
}

/* .cancel{
flex-basis: 78%;
} */

.cancel_btn {
    border-width: 1px;
    border-color: #d0d0ce;
}

.img_div {
    height: inherit;
    align-items: center;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    flex-direction: column;
}

.img_div p {
    line-height: 24px;
    text-align: center;
    font-size: 16px;
    padding: 15px 0;
}