.toggle {
  text-transform: capitalize;
  position: relative;
}
.toggle > span {
  display: flex;
  align-items: center;
}
span.badge {
  display: flex;
  width: 16px;
  height: 16px;
  padding: 0 !important;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.toggle span.badge {
  top: -6px;
  left: 3px;
}
