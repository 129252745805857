.planCard {
    width: 296px;
    min-height: 350px;
    margin-bottom: 16px;
}
.planCard:not(:last-child) {
    margin-right: 1rem;
}

.disabled {
    background-color: #F5F5F5;
    pointer-events: none;
}
.warningText {
    margin-left: 10px;
    font-size: 14px;
    line-height: 24px;
}

.card_header {
    padding: .25rem 1.25rem;
    margin-bottom: 0;
    /* background-color: rgba(0, 0, 0, .03); */
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    text-transform: capitalize;
}

.card_main {
    padding: 0;
}

.card_body {
    padding: .75rem 1.25rem;
}

.card_footer {
    padding: 0;
    border: 0;
    bottom: 10px;
    position: absolute;
    max-width: 90%;
    min-width: 274px;
    background-color: inherit;
}

.card_discription {
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 320px;
}
.card_sub_title {
    display: flex;
    align-items: baseline;
    padding: 1rem 0;
    border-bottom: 1px solid #000000;
    margin: 0;
    margin-bottom: 1rem !important;
}

.licenseSection {
    display: block;
    padding: 20px 20px 20px 0px;
}
.subscriptionSection{
    display: block;
    padding-right: 20px;
}
.labelText {
    float: left;
}
.labelValue{
    float: right;
    text-align: right;
}
.rightAligned{
    text-align: right;
}
.card_footer{
    padding: 0 !important;
    border-top: 0 !important;
}
/* .card_discription{
    min-height: auto !important;
} */
.durationValue {
    padding-bottom: 20px;
    float: right;
    text-align: right;
}

.licencesInput::-webkit-inner-spin-button, 
.licencesInput::-webkit-outer-spin-button {  
   opacity: 1;
}
.selectYears {
    width: 100%;
}

.headLabel {
    font-size: 16px;
    font-weight: 600;
}

.status {
    display: inline-block;
    width: 8px;
    height: 8px;
    /* border: 1px solid transparent; */
    border-radius: 50%;
    margin-right: 5px;
}

.cancelled {
    /* background-color: #000000; */
    background-color: #FD0713;
}

.rejected {
    /* background-color: #D0D0CE; #FD0713 */
    background-color: #FD0713;
}

.active {
    background-color: #86BC25;
}

.expired {
    background-color: #FD0713;
}
.unsubscribed {
    background-color: #63666A;
}

.card.highlighted {
    border: 1px solid #007CB0;
    background-color: #FAFDFF;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
}

.noBorder {
    border-top: 1px solid transparent !important;
}

.noBorder.highlighted {
    background-color: #FAFDFF;
}

.h2 {
    margin-bottom: 16px;
}
.inactive{
    background-color: #FD0713;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 6rem;
    margin-top: 20px;
    padding: 20px 15px 20px 20px;
    background-color: #F7F7F7;
}

.stepActionPanel {
    bottom: 0;
}

.footerLabel {
    line-height: 30px;
}

.actionSelect {
    display: flex;
    /* flex-direction: column; */
    height: 15px;
    /* margin-left: auto; */
}

.actionLabel {
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #d0d0ce;
    font-weight: bold;
    font-size: 10px;
    /* line-height: 16px; */
    /* padding: 0 7px; */
}

.dropdownaction {
    width: 150px;
}

/* .option {
    display: flex;
    padding: 5px 6px;
    background: #ffffff;
    justify-content: space-between;
    z-index: 9999;
    cursor: pointer;
}

.option:hover{
    background-color: #EBF8FD;
} */
.reactivate_icon{
    margin-left: 2px;
    margin-right: 1px;
}

.cancel_option{
    color: #DA291C;
}

.updateSubscription {
    min-width: unset;
    width: 24px;
    height: 24px;
}

.updateSubscription span {
    position: relative;
    bottom: 3px;
}

.licencesInput {
    width: 64px;
    height: 24px;
    /* margin-right: 7px; */
    margin-left: 7px;
    box-shadow: none;
    border: thin solid #d0d0ce;
}
.licenseDisplayLabel 
{
    width: 64px;
    height: 24px;
    margin-right: 7px;
    margin-left: 7px;
    box-shadow: none;
    text-align: center;
}

.editPlan {
    display: flex;
    float: right;
    padding-bottom: 20px;
}

.reactivate_icon {
    margin-left: 2px;
    margin-right: 1px;
}

.cancel_option {
    color: #DA291C;
}

.selectedOption {
    display: none;
    color: #007CB0;
}

.action div[class$="control"] {
    box-shadow: none;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    letter-spacing: 0.5px;
    min-height: 18px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    flex-wrap: nowrap;
}

.action div[class$="control"]>div:first-of-type {
    padding: 0 5px;
    width: 100%;
    min-width: 350px;
    text-transform: uppercase;
    /* margin-bottom: 20px; */
}

.action div[class$="singleValue"] {
    color: #fff;
    text-align: right;
}

.action div[class$="IndicatorsContainer"] {
    padding-right: 0;
}

.action div[class$="IndicatorsContainer"]>span {
    font-size: 8px;
    color: #ffffff;
    padding: 0;
}

.actionfbSelect {
    display: flex;
    flex-direction: row;
    /* margin-left: auto; */
    cursor: pointer;
    position: relative;
}

.placeholder {
    display: flex;
    font-weight: 600;
    color: #007CB0;
    cursor: pointer;
}