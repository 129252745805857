.content {
  /* top: 72px;
    position: absolute; */
  padding: 0 20px;
  width: inherit;
  margin: 20px 0;
}

.footer {
  display: flex;
  /* height: 72px; */
  box-shadow: inset 0px 1px 0px #ebebeb;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}

.footer button {
  padding: 3px 12px;
}

.footer button:disabled {
  background-color: #d0d0ce !important;
  opacity: 1 !important;
  border-color: #d0d0ce !important;
}

.cancel_btn {
    border: none;
    background: none;
    color: #007CB0;
}
.concentContainer {
  display: flex;
  background: #f1f8fb;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 16px 0px;
  margin: -5px 24px 0px 24px;
}
.concentItems {
  padding: 16px 0px;
}
.headerText {
  font-weight: bold;
  float: left;
  margin-right: 60px;
}
.toggleIcon {
  float: right;
  margin: 16px 0 0 60px;
}
.detailsSection {
  height: 400px;
  overflow-y: auto;
}
.userSection {
  height: 136px;
}
.alertContainer {
  padding: 5px 15px;
}

.footerMul {
  display: flex;
  /* height: 72px; */
  box-shadow: inset 0px 1px 0px #ebebeb;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  border: 1px dashed lightblue;
  padding: 1rem;
  text-align: center;
  color: #212529;
}
.outdrop {
  background-color: #F1F8FB;
  padding: 12px;
  margin: 15px;
}

.error {
  color: var(--red-700);
  font-size: 16;
  font-weight: 600;
}

.templateButtonDownloadIcon {
  margin-right: 8px;
}

.templateButton {
  border: none;
  background: none;
  text-decoration: none;
  font-weight: bold;
  color: #007cb0;
}

.clear-files-button {
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: right;

  & button {
    color: var(--blue-500);
    font-size: 14px;
    padding: 6px 0;
    background-color: transparent;
    border-width: 0;

    &:hover {
      text-decoration: underline;
    }
  }
}

.uploadedfiles {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.uploadedfilesitem {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.uploadedfileserror {
  padding-left: 0;
  margin: -2px 0px 4px 21px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  color: red;
}

.uploadedfilesdelete {
  padding-left: 0;
  margin-bottom: 0;

  flex-direction: column;
  row-gap: 8px;
  display: grid;
  grid-template-columns: 30px 2fr 75px;
  text-align: left;
  margin-bottom: 0;

  width: 24px;
  height: 24px;
  padding: 6px;
  background-color: transparent;
  border-width: 0;
  margin-left: 8px;

  :hover {
    background-color: #f2f2f2;
  }

  :focus-visible {
    outline: 2px solid #000;
  }

  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.uploadedfilesitemwrap {
  margin-bottom: 5px;
}

.uploadedfilesitemcontent {
  padding-left: 0;
  margin-bottom: 0;

  flex-direction: column;
  row-gap: 8px;
  grid-template-columns: 30px 2fr 75px;
  text-align: left;
}

.dropfilearea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--light-blue);
  padding: 64px;
  outline: 1px dashed var(--blue-500);
  outline-offset: -8px;
  margin-bottom: 15px;
  text-align: center;
  &--focus {
    opacity: 0.5;
  }

  &--disabled {
    background-color: #f5f5f5;
    outline: 1px solid #a7a8aa;
  }
  .drop-file-area__browse::before {
    color: #a7a8aa;
  }
}

.filebrowse {
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 64px;

  outline-offset: -8px;
  margin: -3px 0px 5px 5px;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 3px 0;
  pointer-events: all;
  font-weight: bold;
  color: #007cb0;
  :hover {
    text-decoration: underline;
  }
}
.filebrowsetext {
  pointer-events: none;
}
input[type='file'] {
  display: none;
}
.spndragdrop {
  display: flex;
  font-weight: bold;
}
.xlsimage {
  margin-top: -4px;
  margin-left: 3px;
}

.excelFileStatus {
  display: flex;
  margin-top: 10px;
}
.arrangeItems {
  margin-left: 20px;
}
.closeStatus {
  position: absolute;
  top: 90px;
  right: 45px;
}
.barUploading {
  margin: 10px 27px 10px 22px;
  border-radius: 5px !important;
  background-color: rgb(182, 188, 226) !important;
}

.barFailed {
  margin: 10px 27px 10px 22px;
  border-radius: 5px;
  background-color: #da291c !important;
}
.barLoaded {
  margin: 10px 27px 10px 22px;
  border-radius: 5px;
  background-color: #046a38 !important;
}
.barSelected {
  margin: 10px 27px 10px 22px;
  border-radius: 5px !important;
  background-color: rgb(182, 188, 226) !important;
}
