.toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.toggleBox {
  display: flex;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background: #FFFFFF;
  border: 1px solid #D0D0CE;
  box-sizing: border-box;
  border-radius: 100px;
  color: #000000;
}
.toggleIcon {
  color: #fff;
  margin-left: 8px;
}
