button{
  text-transform: uppercase;
}
.modal-title {
  text-transform: capitalize;
}


.modal-title, .modal-title.h4 {
    font-size: 20px;
    font-weight: normal;
    line-height: 32px;
  }

.confirm-dialog h4, .change-settings-txt {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.icon-cog-special {
  font-size: 24px;
}
.confirm-dialog .icon-lightbulb-special {
    color: white;
    background-color: red;
    border-radius: 50%;
    padding: 1px;
    font-size: 32px;
    height: 33px;
}
.first-language {
  width: 80%;
}

  .modal-header,
  .modal-footer {
    padding-left: 24px;
    padding-right: 24px;
  }
  .modal-footer > button {
    padding: 3px 0;
  }
  .modal-footer > .cancel-btn {
    color: blue;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-color: blue;
  }
  img.illustration {
    position: absolute;
    right: 100%;
    height: 100%;
  }
  .confirm-dialog.modal {
    left: 100px;
  }
  .warning-style {
    margin-left: 15px;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    display: inline-flex;
  }
