nav.navbar {
  padding: 10px 24px;
}

.brand {
  padding: 0 1rem;
  margin: 0 !important;
}

.logo {
  font-size: 1px !important;
}

.separator {
  width: 1px;
  height: 16px;
  background-color: #53565a;
}

.tab {
  margin-right: 32px;
}

.tab:first-of-type {
  margin-left: 48px;
}

.headerCommon {
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}

.headerUserInfo {
  display: inline-flex;
  margin-left: auto;
}

.headerUserInfo button:focus{
  outline: none;
}

.headerUserInfo button{
  position: relative;
  /* margin-right: 15px; */
}

/* .headerUserInfo button::after{
  border-right: 1px solid #D0D0CE;
  display: inline-block;
  content: '';
  position: absolute;
  width: 1px;
  height: 20px;
  top: 15%;
  right: 0;
} */

.headerSubtitle{
  font-weight: bold;
  font-size: 20px;
  padding: 0;
  margin: 0;
  color: #86bc25;
}