.wrapper {
  display: flex;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  background-color: #f5f5f5;
  padding: 57px 0px 0px;
  position: relative;
}

.footer_wrapper {
  display: flex;
  width: 100%;
  margin: 0 auto;
  background-color: #f5f5f5;
  padding: 75px 25px 25px;
  position: relative;
}

.container {
  min-height: 100%;
  background-color: #fff;
  padding: 0;
  border-radius: 2px 2px 0 0;
  box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.06);
  position: relative;
  display: flex;
  flex-direction: row;
  flex: auto;
  margin: 24px;
}

.container > main {
  min-height: 100%;
  padding: 10px;
  width: 100%;
}

.container > span {
  position: absolute;
  top: -31px;
  left: -10px;
}

