.wrapper {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding-top: 20px;
  margin-top: -15px;
}

.content {
  width: 608px;
}

.update_actionPanel{
  bottom: 0;
}

.border_rule {
  border-top: 1px solid #E1E5EB;
}

.checkboxContainer{
  border-top: 1px solid #d4d4d4;
  padding-top: 20px;
  margin-bottom: 50px;
}