.container {
  line-height: 16px;
}

.check {
  display: none
}

.label {
  margin-left: 5px;
  margin-right: 5px;
}

.label input{
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.label::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #D0D0CE;
  background-color: #FFF;
  border-radius: 50%;
}

.check:checked + .label::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: #FFF;
  border-radius: 50%;
  transform: scale(0.35);
}

.check:checked + .label::before {
  background-color: #007CB0;
  border-color: #007CB0;
}
