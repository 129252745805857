.form {
  display: block;
}
.label {
  font-weight: 600;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  margin-right: 5px;
  color:#757575;
}
.form .feedback {
  font-size: 0.6875rem;
  line-height: 1rem;
  margin: 0;
  margin-bottom: -15px;
}
.form input.formControlInvalid,
.form select.formControlInvalid {
  background-color: #fceeed;
  background-image: none;
}
.specialLabelCss {
  padding-right: 40px;
  font-weight: normal;
  color: #000;
}

.noResize {
  resize: none;
}

.form .auditClient{
  color:#ff0000;
}

.label input{
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.content {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: -1rem !important;
}