.paginator {
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;
  align-items: center;
}

.pageItem {
  border-radius: 2px;
  overflow: hidden;
  margin-right: 15px;
  min-width: 24px;
}

.pageControl {
  margin-right: 12px;
}

.pageControl:last-child {
  margin-right: 0;
}

.controlIcon {
  font-weight: bold !important;
}

.ellipsis {
  font-size: 1rem;
  line-height: 24px;
  margin-right: 15px;
  min-width: 24px;
  text-align: center;
}
