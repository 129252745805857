.submission {
  display: grid;
  grid-template-columns: repeat(4, minmax(25%, 25%));
  grid-gap: 10px;
  padding: 18px;
}

.detailDialogGrid {
  margin-top: 24px;
}

.detailDialog div[class$='header'] {
  padding-top: 1.1rem;
  padding-left: 24px;
  border: none;
  position: relative;
}

.detailDialog div[class$='header']:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 24px;
  right: 24px;
  height: 1px;
  background-color: #D6D6D6;
}

.detailDialog div[class$='body'] {
  padding: 24px;
}

.detailDialog div[class$='content'] {
  border: none;
}

.detailDialog div[class$='header'] > div {
  text-transform: none;
  font-size: 20px;
}

.detailDialog span[class^='icon-'] {
  vertical-align: bottom;
}

.detailDialog div[class*='dataRow'] > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
}