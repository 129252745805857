.container {
  position: relative;
  padding: 10px 10px 0 86px;
  min-height: 70px;
}

.container .icon {
  position: absolute;
  border: 2px solid black;
  box-sizing: border-box;
  border-radius: 50%;
  top: 10px;
  left: 10px;
}

.container h3 {
  font-size: 0.8125rem;
  line-height: 20px;
  word-break: break-all;
}

.container p{
  word-break: break-all;
}