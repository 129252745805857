.container {
  line-height: 16px;
}

.check {
  display: none
}

.label {
  margin-left: 5px;
  margin-top: -4px;
  line-height: 23px;
}

.label::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #D0D0CE;
  background-color: #FFF;
  border-radius: 2px;
}

.check:checked + .label::before {
  background-color: #007CB0;
  border-color: #007CB0;
}

.checkedIcon {
  display: none;
}

.customcheckedIcon {
  display: none;
}

.check:checked + .label .checkedIcon {
  position: absolute;
  top: -1px;
  left: -4px;
  display: block;
  color: #FFF;
  font-weight: bold;
}


.check:checked + .label .customcheckedIcon{
  position: absolute;
  top: -6px;
  left: -16px; 
  display: block;
  color: #FFF;
  font-weight: normal; 
}