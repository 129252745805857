div.header {
  padding: 1rem 1.5rem;
  align-items: center;
}

div.title {
  font-size: 20px;
  color: #000;
  text-transform: capitalize;
}
div.titleAdminDialog {
  font-size: 20px;
  color: #000;
  text-transform: capitalize;
  width:100%;
}
.noBorder {
  border: 0px !important;
}
.paddingTop_0 {
  padding-top: 0px !important
}
.paddingBottom_0 {
  padding-bottom: 0px !important;
}

div.title_caps{
  font-size: 20px;
  color: #000;
  text-transform: none;
}

div.footer {
  padding: 1rem 1.5rem;
}
button.close {
  min-width: 30px;
  min-height: 30px;
  border: none !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #53565a !important;
}
div.footer button.action {
  margin-left: 16px;
  margin-right: 0;
  min-width: 80px;
}

.modal div[class$='modal-content'] {
  border-radius: 0;
}

.modal .header div[class*='modal-title'] {
  line-height: 28px;
}

.warningImage{
  margin-right: 12px;
}
.warningImage img {
  margin-top:-2px;
}
.crossImage{
  margin-left: 12px;
  float: right;
  
}
.crossImage img {
  margin-top:-2px;
}