.container {
  color: #000;
  font-size: 14px;
  margin-bottom: 16px;
  line-height: 24px;
}

.container:last-of-type {
  margin-bottom: 24px;
}

.title {
  font-weight: 600;
}

.container > * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.hiddenContent {
  width: 125px;
  display: inline-table;
  font-style: italic;
  color: #A7A8AA;
}
.text{
  font-size: 14px;
  color: #53565A;
}
