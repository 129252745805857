.container {
  display: flex;
  justify-content: center;
}

.grid {
  width: 95%;
}

.grid thead > tr > th:nth-child(2) {
  width: 200px;
}

.grid thead > tr > th {
  font-size: 12px;
  color: #97999B;
}

.grid thead > tr,
.grid tbody > tr {
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.04);
}

.iconButton {
  border: none;
  background-color: transparent;
}

.iconButton:focus {
  outline: none;
}

.iconButtonDisable {
  pointer-events: none;
  opacity: 0.3;
}
.idButton {
  display: inline;
  color: #007CB0;
}

.spinner {
  margin: auto;
}

.overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fixSpan {
  width: 108px;
  display: inline-block;
}

.hiddenContent {
  width: 125px;
  display: inline-table;
  font-style: italic;
  color: #A7A8AA;
}

.editField {
  width: 96px;
  border: none;
  background-color: #f5f5f5;  
}

.editField:focus-visible {
  outline: 0;
}

.editFieldImg {
  border: none;
  background-color: #f5f5f5;
}

.editImg {
  margin-bottom: 18px;
} 