.container {
  padding: 0.85rem 1.5rem; 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: inset 0 -1px 0 0 #E1E5EB;
}

.container > h1 {
  font-size: 20px;
}