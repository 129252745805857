.container {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: transparent;
  margin-right: 7px;
}

.approved {
  background-color: #009A44;
}
.status-text-approved {
  color: #009A44;
  background-color: rgba(0, 154, 68, .12);
}

.active {
  background-color: #009A44;
}
.status-text-active {
  color: #009A44;
  background-color: rgba(0, 154, 68, .12);
}

.rejected {
  /* background-color: #63666A; */
  background-color: #FD0713;
}
.status-text-rejected {
  color: #63666A;
  background-color: rgba(99, 102, 106, .12);
}

.cancelled{
  /* background-color: #000000; */
  background-color: #FD0713;
}
.unsubscribed {
  background-color: #63666A;
}
.status-text-cancelled {
  color: #000000;
  background-color: rgba(0, 0, 0, .12);
}

.pendingreactivation {
  background-color: #ED8B00;
}
.status-text-pendingreactivation {
  color: #ED8B00;
  background-color: rgba(237, 139, 0, .12);
}

.pendingrenewal{
  background-color: #ED8B00;
}
.status-text-pendingrenewal {
  color: #ED8B00;
  background-color: rgba(237, 139, 0, .12);
}

.pendingactivation{
  background-color: #ED8B00;
}

.status-text-pendingactivation {
  color: #ED8B00;
  background-color: rgba(237, 139, 0, .12);
}

.pending {
  background-color: #ED8B00;
}
.status-text-pending {
  color: #ED8B00;
  background-color: rgba(237, 139, 0, .12);
}

.expired{
  background-color: #FD0713;
}
.status-text-expired {
  color: #ED8B00;
  background-color: rgba(253, 7, 19, .12);
}

.inactive{
  background-color: #FD0713;
}
.status-text-inactive {
  color: #ED8B00;
  background-color: rgba(253, 7, 19, .12);
}
