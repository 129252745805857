
.sidebar {
    min-height: 100%;
    width: 0;
    position: absolute;
    z-index: 1;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 12px;
    top: 0;
    bottom: 0;
  }
  
  .sidebar p {
    padding: 9px 8px 2px 8px;
    text-decoration: none;
    font-size: 14px;
    color: #000000;
    display: block;
    transition: 0.3s;
  }
  
  .sidebar a:hover {
    color: #f1f1f1;
  }
  
  .sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  .openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: transparent;
    color: #000000;
    padding: 5px 15px;
    border: none;
    position: absolute;
    bottom: 25px;
    /* margin-left: -16px; */
    left: 1px;
  }
  
  /* .openbtn:hover {
    background-color: #444;
  } */
  
  .sidebarToggle {
    transition: margin-left .5s;
    padding: 16px;
    /* margin-left: 0px; */
    /* margin-right: 10px; */
    width: 48px;
    min-height: 100%;
    background: #ffffff;
    box-shadow: inset -1px 0px 0px #e0e0e0;
    position: relative;
  }
  
  .sidebarToggle .tab {
    margin-left: -18px;
    padding: 6px 14px;
    display: inline-grid;
  }

  .sidebarToggle .tab:hover {
    background-color: transparent;
}
  
  .sidebarToggle .tab img {
    display: inline-block;
  }
  
  
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidebar {padding-top: 15px;}
    .sidebar a {font-size: 18px;}
  }

  /* .tab {
    margin-right: 32px;
  }
  
  .tab:first-of-type {
    margin-left: 48px;
  } */