.container {
  width: 100%;
  height: 100%;
  grid-column-start: 1;
  grid-column-end: none;
}

.flexTableRowHeightAllProduct {
  grid-template-rows: 50px;
}

.flexTableRowHeightMIP {
  grid-template-rows: auto;
}


.flexTable {
  display: grid;
  overflow: hidden;
}

.flexTable.header {
  font-weight: 700;
  font-size: 12px;
}

.flexTable.detailsGridHeader {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  margin-bottom: 4px;
}

.flexTable > * {
  padding: 0.5em 0.5em;
}

.flexTable.detailsGridHeader > div {
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
}

.mainHeader {
  margin-bottom: 30px;
}
.mainGridHeader:nth-child(2) {
  padding-left: 3em;
}
.mainGridHeader:nth-child(3),
.mainGridHeader:nth-child(4) {
  padding-left: 2em;
}
.mainGridHeader:nth-child(5),
.mainGridHeader:nth-child(6) {
  padding-left: 1.5em;
}
.mainGridHeader:nth-child(7) {
  padding-left: 1em;
}

.dataRow > * {
  font-size: 13px;
}

.detailsDataRow > * {
  font-size: 14px;
  line-height: 24px;
  padding: 4px  0;
  color: #53565A;
}

.detailsDataRow > div:nth-child(2) {
  padding-left: 0;
}

.detailsDataRow > div:nth-child(3) {
  position: relative;
}

.detailsDataRow > div:nth-child(3) > span:first-of-type {
  position: absolute;
  top: 15px;
  left: 9px;
  width: 6px;
  height: 6px;
}

.detailsDataRow > div:nth-child(3) > span:nth-of-type(2) {
  font-weight: 700;
 font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 4px 8px 4px 20px;
}

.flexTable.dataRow:hover {
  background-color: #d3d3d3;
}

.noDataText {
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  letter-spacing: 0.05rem;
  padding-bottom: 20px;
}

.gridItems {
  background-color: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
}

.mTop30 {
  margin-top: 30px;
}

.submissionPlans {
  border-bottom: 1px solid #d0d0ce;
}


.bgWhite {
  background-color: #ffffff;
}

.paddingExpandedRow {
  padding-left: 40px;
  padding-right: 28px;
}

.alignBottom:nth-last-child(1), .alignBottom:nth-last-child(2) {
  display: flex;
  align-items: flex-start;
  width: max-content;
  justify-content: flex-start;
}

.columnheaders {
  display: flex;
  justify-content: flex-start;
}

.disableSortIcon {
  color: #75787B;
  opacity: .5;
}

.highlight {
  background-color: #b3b3b3;
  border: 1px solid #4d4d4d;
}