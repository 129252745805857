.stepper {
  list-style: none;
  display: flex;
  padding: 0 0 20px 0;
  color: #97999B;
  background-color: #fff;
  /* box-shadow:  inset 0px -1px 0px #E0E0E0;; */
}
.handleStepperMargin {
  width: 50%;
  margin-left: 25%;
}
.stepContainer{
  width: 100%;
  background-color: #fff;
}
/* .borderBottom{
  border-bottom: 1px solid #E1E5EB;
} */

.hr {
  margin: 1.3rem 0 2rem 0;
}