
.message-container {
    padding: 15px;
}
.message-container .icon{
    font-size: 32px !important;
    width: 32px;
    height: 32px;
    color: #ffffff;
    border-radius: 50%;
}

.message-container .changeSettingsTxt {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
}

.error {
    border: 1px solid red;
}
.bg-error-icon {
    background-color: red;
}

.warning{
    border: 1px solid yellow;
}
.bg-warning-icon {
    background-color: yellow;
}