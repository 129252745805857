.cross {
    cursor: pointer;
}

.modalHeader.modalHeader {
    border-bottom: none;
}

.modalFooter.modalFooter {
    border-top: none;
}

.modalTitle.modalTitle {
    text-transform: none;;
}