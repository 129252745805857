.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 1.5rem; */
  background-color: white;
  padding: 23px 22px;
  margin: -115px 0px;
}

.paginator {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  z-index: 0;
}