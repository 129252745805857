.languageSelect {
    display: flex;
    flex-direction: column;
    /* margin-left: auto; */
  }
  
  .languageLabel {
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.86);;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    padding: 0 7px;
  }
  
  .dropdownLanguage {
    width: 190px;
  }
  
  .option {
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.86);

  }
  
  .selectedOption {
    display: none;
    color: #007CB0;
  }
  
  .language div[class$="control"] {
    box-shadow: none;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    letter-spacing: 0.5px;
    min-height: 18px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    flex-wrap: nowrap;
  }
  
  .language div[class$="control"] > div:first-of-type {
    padding: 0 5px;
    width: 100%;
    min-width: 150px;
  }
  
  .language div[class$="singleValue"] {
    color: #fff;
    text-align: right;
  }
  
  .language div[class$="IndicatorsContainer"] {
    padding-right: 0;
  }
  
  .language div[class$="IndicatorsContainer"] > span {
    font-size: 8px;
    color: rgba(0, 0, 0, 0.86);
    padding: 0;
    margin-right: 20px;
  }
  
  .languagefbSelect {
    display: flex;
    flex-direction: row;
    /* margin-left: auto; */
    cursor: pointer;
    position: relative;
  }
  
  .fallbackLanguage {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #d0d0ce;
  }
  
  .iconCircle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #d0d0ce;
    margin-right: 8px;
  }
  
  .icon {
    color: #d0d0ce;
  }
  
  .tooltipText {
    visibility: hidden;
    width: 302px;
    background: #000000;
    opacity: 0.9;
    border-radius: 2px;
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding: 4px 8px;
    position: absolute;
    z-index: 1;
    top: 40px;
    right: 0;
  }
  
  .tooltipText::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 45%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #000000 transparent;
  }
  
  .languagefbSelect:hover .tooltipText{
    visibility: visible;
  }
  .shapeLang {
    position: absolute;
    margin: -2px -15px 0 -20px;
  }
  