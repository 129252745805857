.selectedFilter {
    display: flex;
    padding: 1rem 1.5rem;
    gap: 12px;
  }
  
  .badge {
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis;
    min-width: 118px;
    height: 28px;   
    font-weight: 600 !important;
    font-size: 12px !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }

  .removeFilterBtn {
    background: transparent;
    border: transparent;
  }