.tab {
    position: relative;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    transition: background-color .3s;
}

.tab:hover {
  color: #FFFFFF;
  background-color: #292929;
}

  
.tabActive::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 0;
  height: 4px;
  width: 100%;
  background-color: #86BC25;
}

.tabActiveSidePanel::after {
  content: "";
  position: absolute;
  left: 2px;
  height: 100%;
  width: 3px;
  background-color: #86BC25;
}