.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 420px;
  text-align: center;
  margin: auto;
  min-height: 500px;
}

.container > img {
  max-width: 420px;
}

.text {
  font-size: 16px;
  font-weight: 600;
  margin-top: 40px;
  letter-spacing: 0.05rem
}
