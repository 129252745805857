div[class$='option'].customOption {
  cursor: pointer;
}

div[class$='option'].customOption:active {
  background-color: #D6F0FA;
}

.customOption.selected {
  background-color: #F5FCFE;
  color: black;
}

.customOption.focused {
  background-color: #EBF8FD;
  color: black;
}

.customOption {
  display: none;
}

.customOption.selected > div > span {
  display: inline-block;
  vertical-align: text-bottom;
}

