.content {
  padding: 15px;
  border-bottom: 1px solid #E0E0E0;
}  

.selectContainer {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.filterSelect {
  /* padding: 5px; */
  /* border-bottom: 1px solid #E0E0E0; */
  margin-right: 0 !important;
  margin-left: 0 !important;
}  

.toggleIcon {
  color: #fff;
  margin-left: 5px;
}

.option {
  display: flex;
  justify-content: space-between;
}

.selectedOption {
  color: #007CB0; 
  visibility:hidden;

}

.option:hover span.selectedOption {
  visibility:visible;
} 

.userFilters div[class$='control'] {
  box-shadow: none;
  color: #A3A3A3;
  font-size: 14px;
  letter-spacing: .5px;
  background-color: rgba(0,0,0,0);
  border: 1px solid #D0D0CE;
  flex-wrap: nowrap;
  min-height: 30.72px;
}

.userFilters div[class$='control'] > div:first-of-type {
  /* justify-content: flex-end; */
  padding: 0 5px;
}

.userFilters div[class$='singleValue'] {
  color: #212121;
  text-align: right;
}

.userFilters div[class$='singleValue'] span.selectedOption{
  visibility: hidden;
}

.userFilters div[class$='IndicatorsContainer'] {
  padding-right: 0;
}

.userFilters div[class$='IndicatorsContainer'] > span{
  font-size: 8px;
  color:  #007CB0;
}

.userControl div[class$='control'] {
  max-height: 80px !important;
}  

.userControl div[class$='ValueContainer'] div.content {
  background: yellow !important;
}   

.removeFilterBtn {
background: transparent;
border: transparent;
}

.disableOption {
opacity: 0.5;
pointer-events:none;
}

.enableOption{
pointer-events: auto;
}