.selectContainer {
    position: relative;
}

.iconEdit {
    position: absolute;
    right: 10px;
    top: 9px;
    color: #007CB0;
}

.option {
    display: flex;
    justify-content: flex-start;
}

.selectedOption {
    display: none;
    color: #007CB0;
    margin-left: auto;
}

.userSelect div[class$='control'] {
    box-shadow: none;
    font-size: 14px;
    line-height: 24px;
    flex-wrap: nowrap;
    min-height: 32px;
    border: 1px solid #007CB0;
}

.userSelect div[class$='control'] > div:first-of-type {
    padding: 0 8px;
}

.userSelect div[class$='control'] .userEmail {
    color: #75787B;
    margin-left: 9px;
}

div[class$='singleValue'] > div .userName, div[class$='option'].customOption .userName {
    width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

div[class$='singleValue'] > div .userEmail, div[class$='option'].customOption .userEmail {
    width: 47%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

div[class$='singleValue'] > div .userName {
    /* To align selected email with emails in dropdown */
    width: 40.5%;
}

.userSelect div[class$='singleValue'] {
    text-align: left;
    width: 100%;
}

.userSelect div[class$='IndicatorsContainer'] {
    padding-right: 0;
}

.userSelect div[class$='IndicatorsContainer'] > span{
    font-size: 8px;
    color: #FFFFFF;
}

div[class$='option'].customOption {
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    flex-wrap: nowrap;
}

div[class$='option'].customOption .userEmail {
    color: #75787B;
    margin-left: 16px;
}

div[class$='option'].customOption:active {
    background-color: #D6F0FA;
}

.customOption.selected {
    background-color: #F5FCFE;
    color: black;
}

.customOption.focused {
    background-color: #EBF8FD;
    color: black;
}

.customOption.selected > div > span {
    display: inline-block;
    vertical-align: text-bottom;
}

